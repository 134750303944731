import React from "react";
import styles from './GreenBar.module.css';
import monitor from './../../../img/monitor.png';
import green_dots from './../../../img/green_dots.svg';
import orange_dots from './../../../img/orange_dots.svg';

const GreenBar = () => {
    return  (<section className={styles.GreenBar}>
        <img src={green_dots} alt="" className={styles.GreenDots}/>
        <img src={orange_dots} alt="" className={styles.OrangeDots}/>
        <div className={styles.Background} />
        <img src={monitor} className={styles.Monitor} alt="Monitor z video konferencją"/>
        <h2>Dlaczego Livemeeting ?</h2>
        <p>Najważniejsze zalety platformy: prosta forma, intuicyjność obsługi, brak konieczności instalacji dodatkowego oprogramowania (wystarczy przeglądarka internetowa - chrome, firefox, safari), pełne wsparcie techniczne (przed wydarzeniem, w trakcie i po nim).</p>
    </section>);
};

export default GreenBar;