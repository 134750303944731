import React from "react";
import styles from './Footer.module.css';
import logo from './../../../img/logo.svg';

const Footer = () => {
    return (<div className={styles.Footer}>
        <img src={logo} alt="Live Meeting Logo"/>
        <ul>
            <li>Terms of use</li>
            <li>Privacy Policy</li>
        </ul>
    </div>);
};

export default Footer;