import React from "react";
import styles from './Functions.module.css';
import red_dots from "../../../img/red_dots.svg";
import {functions_list} from './functions_list';
import Function from "./Function/Function";

const Functions = () => {
    return (<section className={styles.Functions}>
        <h2>Funkcjonalność platformy</h2>

        <div className={styles.Fns}>
            {functions_list.map((fn, i) => <Function key={i} img={fn.icon} text={fn.text}/>)}
        </div>

        <img src={red_dots} className={styles.RedDots} alt=""/>
    </section>);
};

export default Functions;