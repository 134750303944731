import icon_1 from './../../../img/icon_1.svg';
import icon_2 from './../../../img/icon_2.svg';
import icon_3 from './../../../img/icon_3.svg';
import icon_4 from './../../../img/icon_4.svg';
import icon_5 from './../../../img/icon_5.svg';
import icon_6 from './../../../img/icon_6.svg';
import icon_7 from './../../../img/icon_7.svg';
import icon_8 from './../../../img/icon_8.svg';
import icon_9 from './../../../img/icon_9.svg';
import icon_10 from './../../../img/icon_10.svg';
import icon_11 from './../../../img/icon_11.svg';
import icon_12 from './../../../img/icon_12.svg';

export const functions_list = [
    {
        icon: icon_1,
        text: 'udział do 7 prelegentów  (okna audio/video)',
    },
    {
        icon: icon_2,
        text: 'obsługa prezentacji i materiałów multimedialnych',
    },
    {
        icon: icon_3,
        text: 'chat z opcją moderacji oraz automatycznym tłumaczeniem na dowolny język',
    },
    {
        icon: icon_4,
        text: 'zadawania pytań',
    },
    {
        icon: icon_5,
        text: 'możliwość udzielenia głosu uczestnikowi',
    },
    {
        icon: icon_6,
        text: 'Ankiety i testy',
    },
    {
        icon: icon_7,
        text: 'Udostępnianie ekranu',
    },
    {
        icon: icon_8,
        text: 'tablica (whiteboard) z opcją udostępnienia go uczestnikom',
    },
    {
        icon: icon_9,
        text: 'udostępnianie plików',
    },
    {
        icon: icon_10,
        text: 'nagranie wydarzenia',
    },
    {
        icon: icon_11,
        text: 'poczekalnia z agendą (przed rozpoczęciem wydarzenia)',
    },
    {
        icon: icon_12,
        text: 'Wysyłanie zaproszeń i rejestracja uczestników ',
    },
];