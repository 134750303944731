import React from 'react';
import Layout from "./components/Layout/Layout";
import Index from "./components/Index/Index";

function App() {
  return (<Layout>
    <Index/>
  </Layout>);
}

export default App;
