import React from "react";
import styles from './Function.module.css';

const Function = props => {
  return (<div className={styles.Function}>
      <div className={styles.RedEllipse}>
          <img src={props.img} alt=""/>
      </div>
      <p>{props.text}</p>
  </div>);
};

export default Function;