import React from "react";
import styles from './Top.module.css';
import top from './../../../img/top.png';

const Top = () => {
    return (<section className={styles.Top}>
        <img src={top} alt=""/>
        <h1>Zorganizujemy każde wydarzenie on-line</h1>
        <p>Livemeeting to platforma do organizacji videokonferencji, szkoleń, webinarów i spotkań roboczych w małej grupie.</p>
        <a href="mailto:info@livemeeting.pl" className="btn-full-green">Skontaktuj się z Nami</a>
    </section>);
}

export default Top;