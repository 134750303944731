import React from "react";
import styles from './Bottom.module.css';
import green_dots from './../../../img/green_dots_2.svg';

const Bottom = () => {
    return (<section className={styles.Bottom}>
        <h2>Jeśli planujecie wydarzenie online i potrzebujecie sprawdzonego partnera technicznego, zapraszamy do kontaktu. </h2>
        <a href="mailto:info@livemeeting.pl" className="btn-full-green">Skontaktuj się z Nami</a>
        <img src={green_dots} className={styles.GreenDots} alt=""/>
    </section>);
};

export default Bottom;