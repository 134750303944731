import React from "react";
import styles from './Laptop.module.css';
import laptop from './../../../img/laptop.svg';

const Laptop = () => {
    return (<section className={styles.Laptop}>
        <img src={laptop} alt="Laptop"/>

        <h2>Kto może skorzystać ?</h2>
        <p>Narzędzie Livemeeting z powodzeniem wykorzystywane jest przez branże: farmaceutyczną, motoryzacyjną  i szkoleniową. Mamy za sobą dziesiątki godzin transmisji podczas obsługi mniejszych i większych wydarzeń.</p>
        <p>W trakcie wydarzenia prelegent ma pełną kontrolę nad referowaną prezentacją, materiałami multimedialnymi oraz wszelkimi dostępnymi aktywnościami.</p>

        <hr/>
    </section>);
};

export default Laptop;