import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import styles from "./Layout.module.css";

const Layout = props => {
    return (<div className={styles.Layout}>
        <Header/>
        {props.children}
        <Footer/>
    </div>);
};

export default Layout;