import React from "react";
import Top from "./Top/Top";
import GreenBar from "./GreenBar/GreenBar";
import Laptop from "./Laptop/Laptop";
import Bottom from "./Bottom/Bottom";
import Functions from "./Functions/Functions";

const Index = () => {
    return (<div>
        <Top/>
        <GreenBar/>
        <Laptop/>
        <Functions/>
        <Bottom/>
    </div>);
};

export default Index;