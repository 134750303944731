import React from "react";
import styles from './Header.module.css';
import logo from './../../../img/logo.svg';

const Hedaer = () => {
    return (<div className={styles.Header}>
        <img src={logo} alt="Live Meeting Logo"/>
        <a href="/" className="btn-green">Pobierz prezentację</a>
    </div>);
};

export default Hedaer;